<template>
  <div>
    <h2>Список партнеров</h2>
    <div class="personal__table overflow-x">
      <table>
        <tr>
          <th><div><b>ФИО партнера</b></div></th>
          <th><div><b>Электронная почта</b></div></th>
          <th><div></div></th>
          <th><div></div></th>
          <th><div><b>Статус</b></div></th>
        </tr>

        <template v-for="(item, index) in partnerList">
          <tr :key="index">
            <td><div>{{ item.name }}</div></td>
            <td><div>{{ item.email }}</div></td>
            <td>
              <div>
                <router-link
                  class="btn"
                  :to="`/personal/partners/edit/${item.id}/`"
                >Изменить</router-link>
              </div>
            </td>
            <td>
              <div>
                <a
                  href="#"
                  class="btn"
                  @click.prevent="changeStatus(item.id, !item.active)"
                >
                  <template v-if="item.active">Деактивировать</template>
                  <template v-else>Активировать</template>
                </a>
              </div>
            </td>
            <td>
              <div>
                <template v-if="item.active">Активен</template>
                <template v-else>Деактивирован</template>
              </div>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PartnerList',
  data() {
    return {
      partnerList: [],
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    getPartnerList() {
      this.partnerList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getPartnerList');

      axios
        .get(url)
        .then((response) => {
          this.partnerList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeStatus(id, active) {
      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'editPartner');

      const params = new URLSearchParams();
      params.append('ID', id);
      params.append('ACTIVE', (active) ? 'Y' : 'N');

      axios
        .post(url, params)
        .then((response) => {
          const { data } = response.data;

          if (data.success) {
            this.getPartnerList();
          }

          this.btnLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getPartnerList();
  },
};
</script>
