<template>
  <section class="tabs-content personal__content personal__content tabs-content--active">
    <AddPartner />

    <PartnerList/>
  </section>
</template>

<script>
import AddPartner from '@/components/AddPartner.vue';
import PartnerList from '@/components/PartnerList.vue';

export default {
  name: 'Partners',
  components: {
    AddPartner,
    PartnerList,
  },
};
</script>
