<template>
  <div>
    <p class="subtitle">
      <b>Введите данные. ВАЖНО! Запишите / запомните пароль - он в системе не хранится!</b>
    </p>

    <form class="form personal__form" @submit.prevent="submit">
      <template v-if="errorMessage">
        <p v-html="errorMessage"></p>
      </template>

      <template v-if="successMessage">
        <p>{{ successMessage }}</p>
      </template>

      <div class="form__row row">
        <div class="col-lg-4 mb-4">
          <label>ФИО партнера</label>
          <input
            type="text"
            placeholder="ФИО"
            class="personal__input personal__form-text"
            required
            v-model="props.name"
          >
        </div>

        <div class="col-lg-4 mb-4">
          <label>Пароль</label>
          <input
            type="password"
            class="personal__input personal__form-text"
            required
            v-model="props.password"
          >
        </div>

        <div class="col-lg-4 mb-4">
          <label>Повтор пароля</label>
          <input
            type="password"
            class="personal__input personal__form-text"
            required
            v-model="props.confirmPassword"
          >
        </div>

        <div class="col-lg-4 mb-4">
          <label>Электронная почта</label>
          <input
            type="email"
            placeholder="Электронная почта"
            class="personal__input personal__form-text"
            required
            v-model="props.email"
          >
        </div>

        <div class="col-lg-4 mb-4">
          <label>СНО</label>
          <input
            type="text"
            placeholder="СНО"
            class="personal__input personal__form-text"
            required
            v-model="props.sno"
          >
        </div>

        <div class="col-lg-4 mb-4">
          <label for="">ИНН</label>
          <input
            type="text"
            placeholder="0000000000"
            class="personal__input personal__form-text"
            required
            v-model="props.inn"
          >
        </div>

        <div class="col-lg-4 mb-4">
          <div class="form__col">
            <template v-for="(item, index) in branchList">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  personal__form-checkbox
                  form__checkbox
                  form__checkbox
                  position-relative
                "
                :key="index"
              >
                <input
                  type="checkbox"
                  :id="`branch-${index}`"
                  class="personal__input form__checkbox checkbox mt-2"
                  :value="item.id"
                  v-model="props.branch"
                >
                <label :for="`branch-${index}`"></label>
                <label :for="`branch-${index}`">{{ item.address }}</label>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <input
            type="submit"
            value="Добавить партнера"
            class="btn btn--big personal__form-btn mt-0"
            v-if="!btnLoading"
          >
          <Preloader v-if="btnLoading"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'AddPartner',
  components: {
    Preloader,
  },
  data() {
    return {
      props: {
        name: '',
        password: '',
        confirmPassword: '',
        email: '',
        sno: 'Патент',
        inn: '',
        branch: [],
      },
      branchList: [],
      successMessage: '',
      errorMessage: '',
      btnLoading: false,
    };
  },
  computed: {
    checkForm() {
      const propsKey = Object.keys(this.props);
      return propsKey.every((key) => this.props[key] && this.props[key].length > 0);
    },
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    getBranchList() {
      this.branchList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getBranchList');

      axios
        .get(url)
        .then((response) => {
          this.branchList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'addPartner');

        const params = new URLSearchParams();
        params.append('NAME', this.props.name);
        params.append('PASSWORD', this.props.password);
        params.append('CONFIRM_PASSWORD', this.props.confirmPassword);
        params.append('EMAIL', this.props.email);
        params.append('UF_SNO', this.props.sno);
        params.append('UF_INN', this.props.inn);
        params.append('UF_BRANCH', this.props.branch);

        axios
          .post(url, params)
          .then((response) => {
            const { data } = response.data;

            if (data.success) {
              this.successMessage = data.message;

              setTimeout(() => {
                this.successMessage = '';
              }, 2000);

              this.$emit('input', true);

              this.props = {
                name: '',
                password: '',
                confirmPassword: '',
                email: '',
                sno: '',
                inn: '',
                branch: [],
              };
            } else {
              this.errorMessage = data.message;
            }

            this.address = '';
            this.btnLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorMessage = 'Не все поля заполнены!';
      }
    },
  },
  created() {
    this.getBranchList();
  },
};
</script>
